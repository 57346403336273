#luz {
  background: rgba(255, 255, 255, 1) !important;
  -webkit-border-bottom-right-radius: 120px;
  -webkit-border-bottom-left-radius: 120px;
  -moz-border-radius-bottomright: 120px;
  -moz-border-radius-bottomleft: 120px;
  border-bottom-right-radius: 150px;
  border-bottom-left-radius: 150px;
  box-shadow: 0px 38px 130px 24px rgba(288, 255, 255, 0.57);
  -webkit-box-shadow: 0px 38px 130px 24px rgba(288, 255, 255, 0.57);
  -moz-box-shadow: 0px 38px 130px 24px rgba(288, 255, 255, 0.57);
  position: absolute;
  width: 400px;
  height: 5px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9999;
  animation: fadein 8s, createBox 8s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes createBox {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.MuiSnackbarContent-root {
  background: #282b2f !important;
  box-shadow: 0 0 2px 2px rgba(242, 0, 14, 0.4) !important;
}
